<template>

  <b-row class="package-details-table__body">
    <b-col sm="4" md="4" lg="3" class="package-details-name">
      <p class="mb-0">
        {{ title }}
      </p>
    </b-col>
    <b-col class="package-details-value">
      <p class="mb-0">
        {{ value }}
        <slot v-if="!value" name="value"/>
      </p>
    </b-col>
  </b-row>

</template>

<script>


export default {
  props: {
    title: String,
    value: [String, Number]
  }
};
</script>
