import i18n from "@/libs/i18n";


const DiscountCategoryEnum = {
    code: 1,
    withoutCode: 2
}

const DiscountCategory = [
    {
        id: 1,
        name: i18n.t('discounts.discounts.code'),
    },
    {
        id: 2,
        name: i18n.t('discounts.discounts.normal'),
    },

];

function filterDiscountCategory(id) {
    return DiscountCategory.find((item) => item.id === id);
}

export {DiscountCategory,DiscountCategoryEnum, filterDiscountCategory};
