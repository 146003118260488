<template>
  <section>
    <wameed-filter-header
        :filterProps='{
      "modalTitle":$t("common.filter_data"),
    "content":content}'
        :searchPlaceholder="$t('common.search') + ' ...'"
        :btnTitle="$t('common.filter_data')"
        :menuTitle="$t('common.order_by')"
        :orderByItems="orderByItems"
        @orderApplicants="orderApplicants"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
    />
  </section>
</template>

<script>
export default {
  props: {

    content: {
      type: Array,
      default: null,
    },
    canOrderByName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      delay: 1000,
      orderBy: {
        name: this.$i18n.t('common.newest'),
        id: 'desc',
      },
    };
  },

  computed: {
    orderByItems() {
      let temp = [
        {
          name: this.$i18n.t('common.newest'),
          id: 'desc',
        },
        {
          name: this.$i18n.t('common.older'),
          id: 'asc',
        },
      ];

      if (this.canOrderByName) {
        temp.push({
          name: this.$i18n.t('common.alphabetical'),
          id: 'name',
        },)
      }

      return temp;
    },
  },
  methods: {
    applyFilter() {
      this.$emit('applyFilter');
    },
    resetFilter() {
      this.$emit('resetFilter');
    },
    orderApplicants(item) {
      this.$emit('orderApplicants', {
        search: item.search,
        order_by: item.order_by,
      });
    },
  },

};
</script>
