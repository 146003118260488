<template>
    <span>
      {{ discountOn }}
      <span v-if="discountOnType">/</span>
      {{ discountOnType }}
    </span>
</template>

<script>

import {DiscountOn, DiscountOnType} from "@/enums/discountOn.enum";

export default {
  props: {
    on: null,
    onType: null,
  },


  computed: {
    discountOn() {
      switch (this.on) {
        case DiscountOn.vendor:
          return this.$t('discounts.ons.vendor');
        case DiscountOn.free_delivery:
          return this.$t('discounts.ons.free_delivery');
        case DiscountOn.products:
          return this.$t('discounts.ons.products');
        case DiscountOn.order_only:
          return this.$t('discounts.ons.order_only');
        case DiscountOn.total_order:
          return this.$t('discounts.ons.double_discount');
        default:
          return this.$t('discounts.ons.delivery_only');
      }
    },
    discountOnType() {
      switch (this.onType) {
        case DiscountOnType.free:
          return this.$t('discounts.ons.types.free');
        case DiscountOnType.order_count:
          return this.$t('discounts.ons.types.order_count');
        case DiscountOnType.destination:
          return this.$t('discounts.ons.types.destination');
        case DiscountOnType.custom:
          return this.$t('discounts.ons.types.custom');
        default:
          return null
      }
    },


  }
}
</script>

