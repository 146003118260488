<template>
  <div class="status-container " :class="cssClass">
    <span class="status-container--text text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    status: null,
  },

  computed: {
    text() {
      switch (this.status) {
        case 1:
          return this.$t('status.active');
        case 2:
          return this.$t('status.soon');
        default:
          return this.$t('status.expire');
      }
    },

    cssClass() {
      switch (this.status) {
        case 1:
          return 'green';
        case 2:
          return 'yellow';
        default:
          return 'red'
      }
    },


  }
}
</script>

