<template>
  <div class="section-heading">
    <div>
      <h1 class="section-heading__title text-semi-24">{{ title }}</h1>
      <p class="section-heading__sub-title">
        <router-link :to="{name:'dashboard'}">
          <home-icon class="dark-icon size-16"/>
        </router-link>
        <template v-for="path in paths">
          <dash-icon/>
          <router-link :key="path.link" :to="{name:path.link}" class="hover:text-secondary   max-1-lines">
            {{ path.title }}
          </router-link>
        </template>

        <dash-icon/>
        <span> {{ title }}</span>
      </p>
    </div>

    <router-link
        v-if="routeName"
        :to="{ name: routeName }"
        class="section-heading__button cursor-pointer"
    >
      <span class="mx-2">{{ btnTitle }}</span>
      <add-circle-icon class="mx-2"/>
    </router-link>

    <div
        v-if="btn"
        @click="btnAction"
        class="section-heading__button cursor-pointer"
    >
      <span class="mx-2">{{ btnTitle }}</span>
      <add-circle-icon class="mx-2"/>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    paths: Array,

    routeName: {
      type: String,
      default: null,
    },
    btnTitle: {
      type: String,
      default: null,
    },
    btn: {
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  methods: {
    btnAction() {
      this.$emit('btnAction');
    },
  },
};
</script>
