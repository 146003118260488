<template>
    <span :class="cssClass">{{ text }}</span>
</template>

<script>
import {DiscountCategoryEnum} from "@/enums/discountCategory.enum";

export default {
  props: {
    category: null,
  },

  computed: {
    text() {
      switch (this.category) {
        case DiscountCategoryEnum.code:
          return this.$t('discounts.with_code');
        default:
          return this.$t('discounts.without_code');
      }
    },

    cssClass() {
      switch (this.category) {
        case DiscountCategoryEnum.code:
          return 'text-talqah-secondary';
        default:
          return ''
      }
    },


  }
}
</script>

